<template>
  <div>
    <v-row>
      <v-col
        v-for="(item, idx) in items"
        :key="item.o ? item.o.id : item.id"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <RaceCard :item="item" :event="event" :index="idx" :color="item.color || $helpers.getRaceColor(event, idx)"></RaceCard>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RaceCard from "@/components/RaceCard.vue";

export default {
  name: "RaceGrid",
  components: {
    RaceCard
  },
  props: {
    event: Object,
    items: Array
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
  }
};
</script>
